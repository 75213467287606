import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { growl } from '../redux/actions/notifications/actionCreators';

import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../layouts/mainLayout';

import { Helmet } from 'react-helmet';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import classnames from 'classnames';

// import withScrollTracker from '../primespot-ui/components/withScrollTracker';
import TestimonialCard from '../components/testimonialCard';
import BeforeAfterImage from '../components/beforeAfterImage';
import Modal from '../primespot-ui/components/modal';
import Toggle from '../primespot-ui/components/toggle';
import NotificationProvider from '../components/notificationProvider';

// import Logo from '../images/alpha-logo.png';
import axios from 'axios';

import {
  Wrapper,
  HeroImage,
  TwoColumn,
  ThreeColumnCompare,
  InputWrapper,
  CTAButton,
  CTASecondaryButton,
  QuoteFormWrapper,
  TestimonalSection,
  BeforeAfterSection,
} from '../styles/pages/carpet-cleaning';

import config from '../config';

export const query = graphql`
  query CarpetCleaningLandingPageQuery {
    hero: file(relativePath: { regex: "/dog-on-carpet.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cmgIcon: file(relativePath: { regex: "/trust-badges/logo-cmg.jpg/" }) {
      childImageSharp {
        fixed(width: 113, height: 52) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bondurantIcon: file(
      relativePath: { regex: "/trust-badges/logo-bondurant.png/" }
    ) {
      childImageSharp {
        fixed(width: 105, height: 55) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bcrIcon: file(relativePath: { regex: "/trust-badges/logo-bcr.png/" }) {
      childImageSharp {
        fixed(width: 113, height: 56) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    terraceViewIcon: file(
      relativePath: { regex: "/trust-badges/logo-terrace-view.png/" }
    ) {
      childImageSharp {
        fixed(width: 135, height: 86) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    longAndFosterIcon: file(
      relativePath: { regex: "/trust-badges/logo-long-and-foster.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 150, height: 113) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    foxridgeIcon: file(
      relativePath: { regex: "/trust-badges/logo-foxridge.png/" }
    ) {
      childImageSharp {
        fixed(width: 158, height: 47) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    riceRealtyIcon: file(
      relativePath: { regex: "/trust-badges/logo-rice-realty.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 113, height: 69) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sasIcon: file(relativePath: { regex: "/trust-badges/logo-sas.jpg/" }) {
      childImageSharp {
        fixed(width: 124, height: 74) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    beforeAfterOne: file(relativePath: { regex: "/cc-gallery-1.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterTwo: file(relativePath: { regex: "/cc-gallery-2.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterThree: file(relativePath: { regex: "/cc-gallery-3.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterFour: file(relativePath: { regex: "/cc-gallery-4.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterFive: file(relativePath: { regex: "/cc-gallery-5.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

class Page extends Component {
  constructor(props) {
    super(props);

    this.heroImageRef = React.createRef();
    this.heroImageRefMobile = React.createRef();
  }
  componentDidMount() {
    this.setHeroAspect();
    window.addEventListener('resize', this.setHeroAspect);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeroAspect);
  }

  setHeroAspect = () => {
    if (this.heroImageRef && this.heroImageRef.current) {
      this.heroImageRef.current.style.height =
        this.heroImageRef.current.offsetWidth + 'px';
    }

    if (this.heroImageRefMobile && this.heroImageRefMobile.current) {
      this.heroImageRefMobile.current.style.height =
        this.heroImageRefMobile.current.offsetWidth + 'px';
    }
  };

  render() {
    const { data } = this.props;
    const headline =
      'Enjoy clean, beautiful, and fresh carpets without pet issues or allergens.';
    const valueProposition = `Do you ever wonder what's in the carpet where your children and pets play?  Your carpets hold dirt, spills, chemicals, allergens, pet accidents, and more.  Fortunately, we are experts at removing all of these issues, so you never have to worry about it again.`;
    return (
      <Layout full>
        <Wrapper>
          <Helmet>
            <link
              href="https://fonts.googleapis.com/css?family=Roboto:400,400i,700"
              rel="stylesheet"
            />
          </Helmet>
          {/*<HeaderWithScrollTracker />*/}
          <TwoColumn className="hero__two-column">
            <div>
              <HeroImage className="hero-image" ref={this.heroImageRef}>
                <Img fluid={data.hero.childImageSharp.fluid} />
              </HeroImage>
              <p style={{ marginTop: '48px' }}>{valueProposition}</p>
              <Toggle>
                {({ toggled: showQuoteModal, toggle: toggleQuoteModal }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    <CTAButton onClick={toggleQuoteModal}>
                      Get Quote Now
                    </CTAButton>
                    <CTASecondaryButton href={config.phoneNumberHref}>
                      Call Us
                    </CTASecondaryButton>
                    {showQuoteModal && (
                      <Modal
                        onClose={toggleQuoteModal}
                        header="Get a professional carpet cleaning quote now."
                      >
                        <QuoteForm
                          bgColor="#FFF"
                          showHeader={false}
                          onSuccess={toggleQuoteModal}
                        />
                      </Modal>
                    )}
                  </div>
                )}
              </Toggle>
            </div>
            <div>
              <h1>{headline}</h1>
              <QuoteForm fullWidthCTA />
            </div>
          </TwoColumn>
          <div className="hero__one-column">
            <h1>{headline}</h1>
            <HeroImage className="hero-image" ref={this.heroImageRefMobile}>
              <Img fluid={data.hero.childImageSharp.fluid} />
            </HeroImage>
            <QuoteForm fullWidthCTA />
            <p style={{ marginTop: '48px' }}>{valueProposition}</p>
            <Toggle>
              {({ toggled: showQuoteModal, toggle: toggleQuoteModal }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  <CTAButton onClick={toggleQuoteModal}>
                    Get Quote Now
                  </CTAButton>
                  <CTASecondaryButton href={config.phoneNumberHref}>
                    Call Us
                  </CTASecondaryButton>
                  {showQuoteModal && (
                    <Modal
                      onClose={toggleQuoteModal}
                      header="Get a professional carpet cleaning quote now."
                    >
                      <QuoteForm
                        bgColor="#FFF"
                        fullWidthCTA
                        showHeader={false}
                      />
                    </Modal>
                  )}
                </div>
              )}
            </Toggle>
          </div>
          <section>
            <h2>Our Standard Packages</h2>
            <ThreeColumnCompare>
              <div>
                <h4>Standard cleaning</h4>
                <ul>
                  <li>Hot water extraction (steam cleaning)</li>
                  <li>Use of industry-standard wand tool</li>
                  <li>Basic spot/stain removal included</li>
                  <li>Moving of small/simple items included</li>
                  <li>6 to 12 hour estimated drying time</li>
                  <li>Recommended in most situations</li>
                </ul>
              </div>
              <div>
                <h4>Low moisture cleaning</h4>
                <ul>
                  <li>Very-low-moisture cleaning</li>
                  <li>Use of specialized equipment for drier cleaning</li>
                  <li>Basic spot/stain removal included</li>
                  <li>Moving of small/simple items included</li>
                  <li>2 to 4 hour estimated drying time</li>
                  <li>Recommended when drying time is a concern</li>
                </ul>
              </div>
              <div>
                <h4>Deep cleaning</h4>
                <ul>
                  <li>Hot water extraction (steam cleaning)</li>
                  <li>Use of specialized rotary scrubbing tool</li>
                  <li>Heavy traffic lane cleaning</li>
                  <li>Basic spot/stain removal included</li>
                  <li>Moving of small/simple items included</li>
                  <li>6 to 12 hour estimated drying time</li>
                  <li>
                    Recommended when the carpets are extra dirty or haven't been
                    cleaned in several years
                  </li>
                </ul>
              </div>
            </ThreeColumnCompare>
          </section>
          <section className="compressed">
            <h2>We also offer a green-clean package!</h2>
            <p>
              Our standard cleaners are very environmentally and pet friendly.
              In the situations where you want to be extra kind to the
              environment, however, we offer a green-clean package.
            </p>
            <p>
              In this package, we use only environmental alternatives to our
              most popular cleaners. These cleaners substitute harsher
              ingredients for those that are milder alternatives. While our most
              popular cleaners are the strongest and best that we use, the
              environmental alternatives are extremely good as well. They can
              get the job done in all but the toughest situations.
            </p>
          </section>
          <section className="compressed">
            <h2>
              We provide services for these fine companies (among others) as
              well as our residential clients.
            </h2>
            <div className="trust-badges">
              <Img
                className="trust-badge"
                fixed={data.cmgIcon.childImageSharp.fixed}
              />
              <Img
                className="trust-badge"
                fixed={data.bondurantIcon.childImageSharp.fixed}
              />
              <Img
                className="trust-badge"
                fixed={data.bcrIcon.childImageSharp.fixed}
              />
              <Img
                className="trust-badge"
                fixed={data.terraceViewIcon.childImageSharp.fixed}
              />
              <Img
                className="trust-badge"
                fixed={data.longAndFosterIcon.childImageSharp.fixed}
              />
              <Img
                className="trust-badge"
                fixed={data.foxridgeIcon.childImageSharp.fixed}
              />
              <Img
                className="trust-badge"
                fixed={data.riceRealtyIcon.childImageSharp.fixed}
              />
              <Img
                className="trust-badge"
                fixed={data.sasIcon.childImageSharp.fixed}
              />
            </div>
          </section>
          <section className="compressed">
            <h2>We have the experience</h2>
            <p>
              With nearly 20 years of experience, we have been a leading
              provider of carpet cleaning services in Blacksburg,
              Christiansburg, Radford, Roanoke, Salem, and the rest of the New
              River Valley for some time.
            </p>
            <p>
              If you open the phone book and look for carpet cleaners, you'll
              likely find more than a dozen available. Few of these have even
              half of the experience that we have dealing with the challenges
              that cleaners face.
            </p>
            <p>Our experience has helped our ability to:</p>
            <ul>
              <li>
                Thoroughly clean carpets without causing any damage to the
                sensitive fibers.
              </li>
              <li>Remove tough stains that other cleaners leave behind.</li>
              <li>Clean carpets fully without leaving them too wet.</li>
              <li>
                Remove odors from the carpets leaving them smelling fresh and
                clean.
              </li>
              <li>
                Remove allergens from the carpets helping you to breathe easier.
              </li>
            </ul>
          </section>
          <TestimonalSection>
            <h2>See What Our Clients Are Saying About Us</h2>
            <div className="testimonial-section__testimonials">
              <TestimonialCard>
                <blockquote>
                  <p>
                    I had very little expectation that this carpet could even
                    look halfway decent. I was getting it cleaned to help with a
                    mold issue in this old house we are renting. I had to be
                    gone for a day or so and during this time they came in to
                    clean the carpet. When I got back and opened the door, I
                    thought I was at the wrong house. It was amazing. They got
                    things out of the carpet that I thought were permanent.
                    Amazing results!
                  </p>
                  <footer>Anonymous</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    Very quick response to our request. Technician showed up
                    exactly on time; was very knowledgable, professional, and
                    pleasant. Work done quickly and looks great. Price very
                    reasonable - and how it was priced was clearly explained
                    before service contract agreed and at the time of payment.
                  </p>
                  <footer>Harris J.</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    Great Service! We were worked into a busy schedule on short
                    notice and the carpets looked great. Also very well priced.
                  </p>
                  <footer>Jason W.</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    Since I moved to northern Virginia I cannot find a carpet
                    company as good as you. Do you know of a good company near
                    Alexandria? I still own a townhouse in Christiansburg and
                    use your services every year. I appreciate the excellent
                    results! It is so hard to find a quality and honest company.
                  </p>
                  <footer>Melissa</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    I needed a large room with wall to wall carpeting cleaned,
                    also an area rug and a set of stairs. In addition, they took
                    away a wool area rug to be cleaned at their place of
                    business.
                  </p>
                  <p>
                    Very well. I was very happy with the results. I felt that
                    the fee was reasonable, though I don't remember exactly what
                    I paid at the time. The person I talked to when I made the
                    appointment, Michael, was very helpful and answered my
                    questions and concerns regarding pet odor on the wall to
                    wall carpeting. I will call them again when the carpets need
                    cleaning.
                  </p>
                  <footer>Anonymous</footer>
                </blockquote>
              </TestimonialCard>
              <TestimonialCard>
                <blockquote>
                  <p>
                    Sci-Tech saved me thousands on carpet replacement. Who would
                    have thought that purple nail polish could come out of white
                    carpet. I thank you and my daughter thanks you.
                  </p>
                  <footer>Nancy</footer>
                </blockquote>
              </TestimonialCard>
            </div>
          </TestimonalSection>
          <BeforeAfterSection>
            <h2>Check Out A Few Before / After Pictures</h2>
            <div className="before-after-section__inner-wrapper">
              <BeforeAfterImage
                thumbnail={
                  <Img fixed={data.beforeAfterOne.childImageSharp.fixed} />
                }
                image={
                  <Img fluid={data.beforeAfterOne.childImageSharp.fluid} />
                }
              >
                <p>
                  This berber carpet was quite filthy. By pre-treating it before
                  cleaning, we were able to remove nearly all of the dirt in the
                  carpet.
                </p>
              </BeforeAfterImage>
              <BeforeAfterImage
                thumbnail={
                  <Img fixed={data.beforeAfterTwo.childImageSharp.fixed} />
                }
                image={
                  <Img fluid={data.beforeAfterTwo.childImageSharp.fluid} />
                }
              >
                <p>
                  This is a different angle of the same carpet in the image
                  before. This carpet was cleaned using our basic cleaning
                  package.
                </p>
              </BeforeAfterImage>
              <BeforeAfterImage
                thumbnail={
                  <Img fixed={data.beforeAfterThree.childImageSharp.fixed} />
                }
                image={
                  <Img fluid={data.beforeAfterThree.childImageSharp.fluid} />
                }
              >
                <p>
                  See the difference in this carpet? This is a thick and cream
                  colored nylon carpet. It looked like new after cleaning.
                </p>
              </BeforeAfterImage>
              <BeforeAfterImage
                thumbnail={
                  <Img fixed={data.beforeAfterFour.childImageSharp.fixed} />
                }
                image={
                  <Img fluid={data.beforeAfterFour.childImageSharp.fluid} />
                }
              >
                <p>
                  This was a large basement carpet that we cleaned. The outside
                  edges of the picture are the areas we've already cleaned.
                  Notice how much brighter the carpet looks.
                </p>
              </BeforeAfterImage>
              <BeforeAfterImage
                thumbnail={
                  <Img fixed={data.beforeAfterFive.childImageSharp.fixed} />
                }
                image={
                  <Img fluid={data.beforeAfterFive.childImageSharp.fluid} />
                }
              >
                <p>
                  This is a picture of our deluxe package on display. The swirl
                  marks in the carpet are from the rotary machine. The dark
                  traffic areas in this carpet are being completely removed.
                </p>
              </BeforeAfterImage>
            </div>
          </BeforeAfterSection>
          <section className="compressed">
            <QuoteForm bgColor="#FFF" headerComponent="h2" fullWidthCTA />
          </section>
        </Wrapper>
      </Layout>
    );
  }
}

const RawQuoteForm = ({
  bgColor,
  headerComponent,
  fullWidthCTA,
  showHeader = true,
  growl,
  onSuccess,
  onError,
}) => {
  return (
    <Fragment>
      <NotificationProvider />
      <Formik
        initialValues={{
          name: '',
          email: '',
          phoneNumber: '',
          zipCode: '',
          requestedAreas: '3',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Your name is required.'),
          email: Yup.string()
            .email('A valid email address is required.')
            .required('Your email address is required.'),
          phoneNumber: Yup.string(),
          zipCode: Yup.string()
            .min(5, 'Zip codes must be at least 5 characters long.')
            .max(10, 'Zip code is too long.')
            .required('Your zip code is required.'),
          requestedAreas: Yup.string().required(
            "Please tell us the number of areas you'd like to have cleaned."
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          values.source = 'Carpet cleaning landing page';
          axios
            .post(`${config.backendServer}/leads`, {
              ...values,
              service: 'carpet cleaning',
            })
            .then(res => {
              growl({
                message:
                  'Thank you.  Your quote request is being processed.  We will be in touch soon!',
                type: 'success',
              });
              onSuccess && onSuccess(res);
            })
            .catch(err => {
              console.error(err);
              growl({
                message:
                  'Something went wrong with your request.  Please try again.',
                type: 'danger',
              });
              onError && onError(err);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <QuoteFormWrapper bgColor={bgColor} showHeader={showHeader}>
            {headerComponent ? (
              React.createElement(
                headerComponent,
                { style: { marginBottom: '66px' } },
                'Get a professional carpet cleaning quote now.'
              )
            ) : (
              <h3>Get a professional carpet cleaning quote now.</h3>
            )}
            <Form>
              <div>
                <label>
                  <span>Your name</span>
                  <InputWrapper>
                    <Field
                      name="name"
                      placeholder="John Smith"
                      component="input"
                    />
                  </InputWrapper>
                </label>
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error-message"
                />
              </div>
              <div>
                <label>
                  <span>Your email</span>
                  <InputWrapper>
                    <Field
                      name="email"
                      type="email"
                      placeholder="john.smith@email.com"
                      component="input"
                    />
                  </InputWrapper>
                </label>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </div>
              <div>
                <label>
                  <span>Your phone number (optional)</span>
                  <InputWrapper>
                    <Field
                      name="phoneNumber"
                      type="tel"
                      placeholder="540-555-1212"
                      component="input"
                    />
                  </InputWrapper>
                </label>
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="error-message"
                />
              </div>
              <div>
                <label>
                  <span>Your zip code</span>
                  <InputWrapper>
                    <Field
                      name="zipCode"
                      placeholder="12345"
                      component="input"
                    />
                  </InputWrapper>
                </label>
                <ErrorMessage
                  name="zipCode"
                  component="div"
                  className="error-message"
                />
              </div>
              <div>
                <label>
                  <span>How many areas do you need cleaned?</span>
                  <Field name="requestedAreas" component="select">
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value="6+">6+</option>
                  </Field>
                </label>
                <ErrorMessage
                  name="requestedAreas"
                  component="div"
                  className="error-message"
                />
              </div>
              <CTAButton
                type="submit"
                disabled={isSubmitting}
                fullWidth={fullWidthCTA}
              >
                {isSubmitting ? 'Loading...' : 'Get Quote Now'}
              </CTAButton>
            </Form>
          </QuoteFormWrapper>
        )}
      </Formik>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ message, type }) => dispatch(growl({ message, type })),
  };
};

const QuoteForm = connect(mapStateToProps, mapDispatchToProps)(RawQuoteForm);

export default Page;
